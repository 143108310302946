import st from "./raffle-side-bar.module.scss";
import MiniTicketIcon from "../../assets/mini-ticket.png";
import Image from "next/image";
import Link from "next/link";

function RaffleMiniSideBar() {
  return (
    <Link href="/raffle" className={st["raffle-mini-side-bar-container"]}>
      <div className={st["icon-container"]}>
        <Image
          src={MiniTicketIcon}
          width={20}
          height={"auto"}
          alt="mini-ticket-icon"
        />

        <div className={st["background-glow"]} />
      </div>
    </Link>
  );
}

export default RaffleMiniSideBar;
