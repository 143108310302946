import React from "react";

function LoginIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#343330"
        d="M13 0a13 13 0 1013 13A13.013 13.013 0 0013 0zm0 24a11 11 0 1111-11 11.012 11.012 0 01-11 11zm2.822-10l1.57 3.944A1.5 1.5 0 0116 20h-6a1.5 1.5 0 01-1.392-2.055L10.178 14a4.5 4.5 0 115.644 0z"
      ></path>
    </svg>
  );
}

export default LoginIcon;
