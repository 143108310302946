import Tippy from "@tippyjs/react";

import st from "../styles/items/ToolTip.module.scss";
import { useRef } from "react";

export default function FlyoutMenu({
  children,
  content,
  placement = "right-start", // Adjust as needed
  size = "small",
  extraClasses,
  distance = 16, // Default distance from the trigger element
  setActiveFlyout,
}) {
  const tippyInstanceRef = useRef(null);

  const flyoutContent = (
    <div className={`${st["flyout-container"]} ${st[size]} ${extraClasses}`}>
      <div className={st["inner-flyout-container"]}>{content}</div>
    </div>
  );

  return (
    <Tippy
      placement={placement}
      duration={[200, 100]}
      animation="shift-away-subtle"
      content={flyoutContent}
      theme="custom-flyout-theme"
      interactive={true}
      // these throw warnings because they are not valid props for Tippy
      // flip={false}
      onCreate={(instance) => {
        tippyInstanceRef.current = instance;
        instance.popper.classList.add("custom-flyout-class");
      }}
      onShow={() => {
        setActiveFlyout(true);
      }}
      onHidden={() => {
        setActiveFlyout(false);
      }}
      appendTo={() => document.body}
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            enabled: false,
          },
          {
            name: "offset",
            options: {
              offset: [0, distance], // First value is horizontal, second is vertical
            },
          },
        ],
      }}
    >
      <div>{children}</div>
    </Tippy>
  );
}
