const SportsSchedule = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      data-icon-name="clock"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M256 0a256 256 0 1 0 0 512 256 256 0 0 0 0-512zm0 461c-112 0-205-93-205-205S144 51 256 51s205 93 205 205-93 205-205 205zm13-333h-39v154l135 80 19-31-115-69z"
      />
    </svg>
  )
  export default SportsSchedule