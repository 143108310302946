const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#5E679E"
      d="m17.163 15.837-3.71-3.712a7.197 7.197 0 1 0-1.328 1.328l3.713 3.714a.94.94 0 0 0 1.329-1.328l-.004-.002ZM2.438 7.75a5.313 5.313 0 1 1 10.625 0 5.313 5.313 0 0 1-10.625 0Z"
    />
  </svg>
);
export default SearchIcon;
