import React from "react";

function RegisterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="19"
      fill="none"
      viewBox="0 0 25 19"
    >
      <path
        fill="#343330"
        d="M24.999 10.813a.813.813 0 01-.813.812h-1.625v1.625a.813.813 0 01-1.625 0v-1.625h-1.625a.813.813 0 110-1.625h1.625V8.375a.813.813 0 011.625 0V10h1.625a.813.813 0 01.813.813zm-11.375 2.201a6.907 6.907 0 10-7.303 0c-2.097.687-3.984 1.97-5.501 3.776a.813.813 0 00.616 1.335H18.5a.813.813 0 00.622-1.335c-1.518-1.806-3.405-3.089-5.497-3.775z"
      ></path>
    </svg>
  );
}

export default RegisterIcon;
