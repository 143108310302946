import useFlags from "@hooks/useFlags";
import { useUserSession } from "@hooks/useUserSession";
import Button from "@items/Button";
import DropDown from "@items/DropDown";
import API from "@lib/api/api";
import PubSub, { EVENTS, usePubSub } from "@lib/pubsub";
import getDictionary from "@lib/tools/getDictionary";
import { useLocale } from "@lib/tools/helpers";
import { log } from "@lib/tools/logger";
import dictionaryAtom from "@recoil/languageAtom";
import userLangAtom from "@recoil/userLang/atom";

import Cookies from "js-cookie";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useId } from "react";
import { useSetRecoilState } from "recoil";

export default function Language(props) {
  const { mini = false } = props;
  const setLanguageAtom = useSetRecoilState(userLangAtom);
  const setDictionary = useSetRecoilState(dictionaryAtom);
  const { langMap } = useFlags();
  const id = useId();
  const locale = useLocale();
  const pathname = usePathname();
  const dictLocale = usePubSub(EVENTS.DICT)?.locale;
  const { hasToken, addTokenHeader } = useUserSession();

  useEffect(() => {
    if (dictLocale === locale) return;
    getDictionary(locale).then((d) => {
      setLanguageAtom(locale);
      Cookies.set("lang", locale);

      setDictionary(d);
      PubSub.publishSync(EVENTS.DICT, d);
    });
  }, [locale, setDictionary, setLanguageAtom, dictLocale]);

  // Set the language and translation attributes on the html element
  useEffect(() => {
    if (typeof document === "undefined") return;

    document.documentElement.lang = locale;
    if (locale !== "en") {
      document.documentElement.setAttribute("translate", "no");
    } else {
      document.documentElement.removeAttribute("translate");
    }
  }, [locale]);

  const setLocaleApi = useCallback(
    (key) =>
      hasToken &&
      API.post("user/update-settings", { language: key }, addTokenHeader())
        .then((r) => r.data)
        .then(({ success }) => success && log("switched language to", key)),
    [hasToken, addTokenHeader],
  );

  return (
    <DropDown
      text={!mini ? langMap[locale].text : null}
      dropDownStyle={"lang-dropdown"}
      classType={[
        "lang-select",
        "two-icons",
        mini ? "btn-language-odds-mobile" : "btn-language-odds",
        mini ? "mini-class-lang" : "",
      ]}
      ValueIcon={
        <Image
          alt="flag"
          src={langMap[locale].src}
          width={16}
          height={16}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      }
    >
      {Object.keys(langMap).map((key) => {
        return (
          <Link
            locale={key}
            href={{
              pathname,
            }}
            key={key + id}
            onClick={() => {
              setLocaleApi(key);
            }}
          >
            <Button
              text={langMap[key].text}
              classType={[
                "drop-down",
                "crypto-display",
                "odds-language-selects",
              ]}
              ValueIcon={
                <Image
                  alt="flag"
                  src={langMap[key].src}
                  width={16}
                  height={16}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              }
              param={langMap[key].code}
            />
          </Link>
        );
      })}
    </DropDown>
  );
}
