const SportsResults = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      data-icon-name="results"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M201 37c-10 0-18 8-18 18v18c0 10 8 18 18 18h110c10 0 18-8 18-18V55c0-10-8-18-18-18H201zm-52 0c8-22 28-37 52-37h110c24 0 44 15 52 37h39c15 0 29 5 39 16 10 10 16 24 16 38v366c0 15-6 29-16 39a55 55 0 0 1-39 16H110c-15 0-29-6-39-16a55 55 0 0 1-16-39V91c0-14 6-28 16-38 10-11 24-16 39-16h39zm217 36c0 30-25 55-55 55H201c-30 0-55-25-55-55h-36c-5 0-10 2-13 6-4 3-6 8-6 12v366c0 5 2 10 6 13 3 4 8 5 13 5h292c5 0 10-1 13-5 4-3 6-8 6-13V91c0-4-2-9-6-12-3-4-8-6-13-6h-36zM131 203c0-10 8-19 18-19h214c10 0 18 9 18 19s-8 18-18 18H149c-10 0-18-8-18-18zm0 96c0-10 8-19 18-19h214c10 0 18 9 18 19s-8 18-18 18H149c-10 0-18-8-18-18zm0 96c0-10 8-19 18-19h214c10 0 18 9 18 19s-8 18-18 18H149c-10 0-18-8-18-18z"
      />
    </svg>
  )
  export default SportsResults