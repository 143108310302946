export default function LiveSupport(props) {
  const fill = props.fill || "#7179A5";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill={fill}
      {...props}
    >
      <path
        d="M11.0007 1.83301C5.94615 1.83301 1.83398 5.94517 1.83398 10.9997V14.7974C1.83398 15.7361 2.65623 16.4997 3.66732 16.4997H4.58398C4.8271 16.4997 5.06026 16.4031 5.23217 16.2312C5.40407 16.0593 5.50065 15.8261 5.50065 15.583V10.8686C5.50065 10.6255 5.40407 10.3923 5.23217 10.2204C5.06026 10.0485 4.8271 9.95192 4.58398 9.95192H3.75165C4.26132 6.40442 7.31382 3.66634 11.0007 3.66634C14.6875 3.66634 17.74 6.40442 18.2497 9.95192H17.4173C17.1742 9.95192 16.941 10.0485 16.7691 10.2204C16.5972 10.3923 16.5007 10.6255 16.5007 10.8686V16.4997C16.5007 17.5108 15.6784 18.333 14.6673 18.333H12.834V17.4163H9.16732V20.1663H14.6673C16.6895 20.1663 18.334 18.5218 18.334 16.4997C19.3451 16.4997 20.1673 15.7361 20.1673 14.7974V10.9997C20.1673 5.94517 16.0552 1.83301 11.0007 1.83301Z"
        fill={fill}
      />
    </svg>
  );
}
