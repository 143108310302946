
const BaseBall = (props) => (

      <svg width={20} height={20} viewBox="0 0 512 512" {...props}>
        <path
          fill="currentColor"
          d="M447 86c42 46 65 107 65 170 0 68-26 129-69 175a346 346 0 0 1-36-72l20-6a12 12 0 0 0 5-5c1-2 1-4 0-7 0-1-1-2-2-3l-2-2c-1 0-2 0-4-1-1 0-2 1-3 1l-20 7a353 353 0 0 1-8-31l23-3c1 0 2-1 3-1l3-3c1 0 1-2 1-3 1-1 1-2 1-3-1-2-2-5-4-6s-4-2-6-2l-24 4-2-21c0-3 1-6 1-9h17c2 0 4-1 6-3s2-4 2-6 0-4-2-6-4-3-6-3h-16l6-29 18 3c2 0 4-1 6-2s3-4 4-6c0-2-1-5-2-6-1-2-4-4-6-4l-16-2 9-29 12 4c2 1 5 1 7 0s3-3 4-5c1-3 1-5 0-7a12 12 0 0 0-5-5l-12-4a368 368 0 0 1 32-69zM103 358a470 470 0 0 1-32 75C27 387 0 325 0 256A254 254 0 0 1 63 89c13 19 26 42 36 66l-14 4a12 12 0 0 0-5 5c-1 2-1 4 0 7 1 2 2 4 4 5s5 1 7 0l14-5 10 29-19 3c-2 0-4 2-6 3-1 2-2 4-2 7 0 2 1 4 3 5 2 2 4 3 6 3h1l21-3 4 29h-17c-2 0-5 1-6 3-2 1-3 4-3 6s1 5 3 6c1 2 4 3 6 3h17c0 10-2 20-3 29l-22-3c-2 0-4 1-6 2s-3 4-4 6c0 1 0 2 1 3 0 1 0 3 1 3l3 3c1 0 2 1 3 1l20 3-8 30-17-6c-1 0-2-1-3-1-1 1-3 1-4 1l-2 2c-1 1-2 2-2 3-1 3-1 5 0 7a12 12 0 0 0 5 5l18 5zm287 7a419 419 0 0 0 40 79c-46 42-107 68-174 68a260 260 0 0 1-172-66 472 472 0 0 0 36-82l18 6c1 1 2 1 3 1 4 0 7-3 9-6v-4c0-1 0-2-1-3 0-1-1-2-2-3-1 0-2-1-3-1l-19-7 9-33 24 4h3c1-1 2-1 3-2s2-2 2-3c1-1 1-2 2-3 0-1 0-2-1-3 0-1 0-2-1-3l-3-3c-1 0-2-1-3-1l-23-3 4-32h18a9 9 0 0 0 9-9c0-2-1-5-3-6-2-2-4-3-6-3h-18l-4-32 23-3c5-1 8-5 8-10-1-1-1-2-1-3l-3-3c-1-1-2-1-3-1-1-1-2-1-3-1l-25 4c-3-11-7-22-11-32l22-8c2 0 4-2 5-4s1-4 1-7c-1-1-1-2-2-3s-2-1-3-2c-1 0-2-1-3-1s-2 0-4 1l-22 7c-12-27-26-53-41-74a241 241 0 0 1 83-55c31-13 64-20 98-20 69 0 132 28 178 73-13 21-26 48-36 77l-24-8c-3-1-5-1-7 0s-4 3-4 5c-1 3-1 5 0 7s3 4 5 4l24 8c-3 11-7 22-9 32l-29-4c-2 0-4 1-6 2s-3 4-4 6c0 1 0 2 1 3 0 1 0 2 1 3l3 3c1 0 2 1 3 1l26 4a333 333 0 0 0-5 31h-20c-2 0-4 1-6 3-1 2-2 4-2 6s1 4 2 6c2 2 4 3 6 3h18v9a185 185 0 0 0 1 23l-20 3c-2 1-4 2-6 4-1 2-1 4-1 6s1 4 3 6c2 1 4 2 6 2l21-3 9 33-16 6c-2 0-4 2-5 4s-1 5-1 7c1 2 2 3 4 4 1 1 3 2 5 2 1 0 2 0 3-1z"
        />
      </svg>
 
)
export default BaseBall
