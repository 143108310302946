import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import st from "../styles/components/SideBar.module.scss";
import Button from "../items/Button";
import Language from "./Language";
import useLanguage from "../hooks/useLanguage";
import ToolTip from "../items/ToolTip";
import SportsList from "./SideMenu/SportList";
import LiveGamesIcon from "../assets/icons/games/LiveGamesIcon";
import GiftIcon from "../assets/icons/general/GiftIcon";
import Accordion from "./Accordion";
import BlackJack from "../assets/icons/games/Blackjack";
import RouletteSlots from "../assets/icons/games/RouletteSlots";
import Baccarat from "../assets/icons/games/Baccarat";
import GameShowIcon from "../assets/icons/games/GameShowIcon";
import LiveSupport from "../assets/icons/general/LiveSupport";
import FlyoutMenu from "../items/FlyoutMenu";
import FlyoutSportsContent from "./SideMenu/FlyoutSportsContent";
import SportsIcon from "../assets/icons/general/Sports";
import LoginIcon from "../assets/icons/general/LoginIcon";
import RegisterIcon from "../assets/icons/general/RegisterIcon";
import BlogIcon from "../assets/icons/general/BlogIcon";
import PromotionsIconNav from "../assets/icons/general/PromotionsIconNav";
import { classnames, resolveDomain, useLocale } from "../lib/tools/helpers";
import { usePathname } from "next/navigation";
import { ChallengeSvg } from "./Svg/SvgStore";
import { CasinoIconNew } from "@assets/icons/general/CasinoIcon";
import { useUserSession } from "../hooks/useUserSession";
import { useIntercom } from "react-use-intercom";
import GamesIcons from "@items/GamesIcons";
import RacesCTA from "./RacesCTA";
import Link from "next/link";
import Soccer from "@assets/icons/sports/Soccer";
import SearchIcon from "@assets/icons/general/SearchIcon";
import { useBreakpoint } from "@hooks/useMobile";
import { NewLogoIcon } from "@assets/icons/general/NewLogo";
import RaffleSideBar from "@/Games/Raffle/components/raffle-side-bar";
import RaffleMiniSideBar from "@/Games/Raffle/components/raffle-side-bar/raffle-mini-side-bar";

// import { MegaPhone } from "@assets/svgs/MegaPhone";
import CsgoIcon from "@assets/icons/general/CsgoIcon";

const slotsList = [
  {
    id: 9,
    name: "Mines",
    url: "mines-game",
    icon: {},
    new: true,
  },
  {
    id: 10,
    name: "Keno",
    url: "keno",
    icon: {},
    // new: true,
  },

  {
    id: 7,
    name: "Case Battles",
    url: "case-battles",
    icon: {},
  },
  {
    id: 1,
    name: "Case Open",
    url: "case-opening",
    icon: {},
  },
  // {
  //   id: 2,
  //   name: "Crash",
  //   url: "crash",
  //   icon: {},
  // },
  {
    id: 3,
    name: "Dice",
    url: "dice",
    icon: {},
  },
  {
    id: 4,
    name: "Plinko",
    url: "plinko",
    icon: {},
  },
  // {
  //   id: 5,
  //   name: "Roulette",
  //   url: "roulette",
  //   icon: {},
  // },
];

const CasinoGames = ({ miniSide, activeItem, setActiveItem }) => {
  const L = useLanguage(["common", "Rewards", "Slots"]);

  return (
    <>
      <Button
        buttonType={"link"}
        url={{
          pathname: "/casino/slots/",
        }}
        classType={miniSide ? ["side-menu-nav"] : ["side-menu-nav"]}
        text={L("slots")}
        showActive={true}
        icon={
          <svg
            id="Icons"
            width="20"
            height="20"
            version="1.1"
            viewBox="0 0 32 32"
          >
            <path d="M29.8,6.2l-3-3.9C26.5,2,26,1.9,25.6,2.1l-1.2,0.6c-2.9,1.4-6.1,1.7-9.2,0.8c-1.3-0.4-2.7-0.5-4-0.5H5C4.4,3,4,3.4,4,4v11.6  c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1c0-1.6,1.3-2.9,3-2.9h2.9l-1.5,1.8c-3.5,4.2-6,9-7.4,14.3C7,29,7,29.4,7.2,29.6S7.7,30,8,30h13  c0.6,0,1-0.4,1-1c0-5.5,1.5-10.9,4.3-15.7l3.5-6C30.1,7,30,6.6,29.8,6.2z" />
          </svg>
        }
        activeItem={activeItem === "slots"}
        method={() => setActiveItem("slots")}
      />
      <Button
        buttonType={"link"}
        url={{
          pathname: "/casino/live/blackjack/",
        }}
        classType={miniSide ? ["side-menu-nav"] : ["side-menu-nav"]}
        text={"Blackjack"}
        showActive={true}
        icon={<BlackJack />}
        activeItem={activeItem === "blackjack"}
        method={() => setActiveItem("blackjack")}
      />
      <Button
        buttonType={"link"}
        url={{
          pathname: "/casino/live/baccarat/",
        }}
        classType={miniSide ? ["side-menu-nav"] : ["side-menu-nav"]}
        text={"Baccarat"}
        showActive={true}
        icon={<Baccarat />}
        activeItem={activeItem === "baccarat"}
        method={() => setActiveItem("baccarat")}
      />
      <Button
        buttonType={"link"}
        url={{
          pathname: "/casino/live/roulette/",
        }}
        classType={miniSide ? ["side-menu-nav"] : ["side-menu-nav"]}
        text={L("roulette")}
        showActive={true}
        icon={<RouletteSlots />}
        activeItem={activeItem === "roulette"}
        method={() => setActiveItem("roulette")}
      />
      <Button
        buttonType={"link"}
        url={{
          pathname: "/casino/live/game-shows/",
        }}
        classType={miniSide ? ["side-menu-nav"] : ["side-menu-nav"]}
        text={L("live_games")}
        showActive={true}
        icon={<GameShowIcon />}
        activeItem={activeItem === "game-shows"}
        method={() => setActiveItem("game-shows")}
      />
      <Button
        buttonType={"link"}
        url={{
          pathname: "/casino/live/",
        }}
        classType={miniSide ? ["side-menu-nav"] : ["side-menu-nav"]}
        text={L("live_casino")}
        showActive={true}
        icon={<LiveGamesIcon />}
        activeItem={activeItem === "live-casino"}
        method={() => setActiveItem("live-casino")}
      />
    </>
  );
};

const RainbetOriginals = ({ miniSide, activeItem, setActiveItem }) => {
  const id = useId();
  const L = useLanguage(["common"]);

  return slotsList.map((item, i) => {
    return (
      <Button
        key={i + id}
        buttonType={"link"}
        url={{
          pathname: `/casino/originals/${item.url}`,
        }}
        classType={[
          miniSide ? "side-menu-nav" : "side-menu-nav",
          item?.new && "new-game",
          miniSide ? "originals" : null,
        ]}
        text={item.name}
        icon={
          <GamesIcons code={item.url === "mines-game" ? "mines" : item.url} />
        }
        showActive={true}
        activeItem={activeItem === item.url}
        method={() => setActiveItem(item.url)}
        extraContent={
          item?.new && (
            <span className={st["new-icon-default"]}>{L("new-game")}</span>
          )
        }
      />
    );
  });
};

const useScrollPosition = (ref) => {
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);
  const handleScroll = useCallback(() => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      setIsTop(scrollTop === 0);
      setIsBottom(scrollTop + clientHeight >= scrollHeight);
    }
  }, [ref]);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, ref]);

  return { isTop, isBottom };
};

export default function NavSideBar(props) {
  const { miniSide, menuToggle, mobileToggle } = props;
  const { hasUserData, hasToken } = useUserSession();
  const L = useLanguage(["common", "Rewards", "Slots"]);
  const { show } = useIntercom();
  const pathname = usePathname() || "";
  const locale_ = useLocale();
  const locale = locale_ !== "en" ? locale_ : "";
  const isMobile = useBreakpoint({ breakPointThree: 390 });

  const menuState = useMemo(() => {
    if (pathname.includes("sportsbook-rules")) {
      return "games";
    } else if (pathname.includes("sports")) {
      return "sports";
    } else {
      return "games";
    }
  }, [pathname]);

  useEffect(() => {
    mobileToggle(false);
  }, [pathname, mobileToggle]);

  const [activeItem, setActiveItem] = useState(null);
  const [activeCasinoFlyout, setActiveCasinoFlyout] = useState(false);
  const [activeSportsFlyout, setActiveSportsFlyout] = useState(false);
  const [activeOriginalFlyout, setActiveOriginalFlyout] = useState(false);

  useEffect(() => {
    switch (true) {
      case pathname.includes("/casino/slots"):
        setActiveItem("slots");
        break;
      case pathname.includes("/casino/live/blackjack"):
        setActiveItem("blackjack");
        break;
      case pathname.includes("/casino/live/baccarat"):
        setActiveItem("baccarat");
        break;
      case pathname.includes("/casino/live/roulette"):
        setActiveItem("roulette");
        break;
      case pathname.includes("/casino/live/game-shows"):
        setActiveItem("game-shows");
        break;
      case pathname.includes("/casino/live"):
        setActiveItem("live-casino");
        break;
      case pathname.includes("/casino/originals"):
        const original = slotsList.find((item) => pathname.includes(item.url));
        if (original) {
          setActiveItem(original.url);
        }
        break;
      default:
        setActiveItem(null);
    }
  }, [pathname]);

  const casinoGamesRef = useRef(null);
  const { isTop, isBottom } = useScrollPosition(casinoGamesRef);

  return (
    <div
      {...classnames(
        st,
        "side-bar-container",
        miniSide ? "mini" : null,
        menuToggle ? "menu-toggle" : null
      )}
    >
      <div className={st["large-version"]}>
        <div className={st["login-register"]}>
          {!hasUserData && isMobile > 2 && (
            <>
              <Button
                key={"login-side"}
                buttonType={"link"}
                query={{ modal: "auth", tab: "login" }}
                classType={["side-menu-nav"]}
                text={L("login")}
                icon={<LoginIcon />}
              />
              <Button
                key={"register-side"}
                buttonType={"link"}
                query={{ modal: "auth", tab: "register" }}
                classType={["side-menu-nav"]}
                text={L("register")}
                icon={<RegisterIcon />}
              />
            </>
          )}

          <Button
            key={"register-side"}
            buttonType={"link"}
            query={{ modal: "search" }}
            classType={["side-menu-nav"]}
            text={L("search")}
            icon={<SearchIcon />}
          />
        </div>
        {!isTop && (
          <div
            className={`${st["gradient"]} ${st["gradient__top"]}`}
            style={isMobile === 3 && hasUserData ? { top: "65px" } : null}
          />
        )}
        <div
          className={`${st["casino-games"]} ${st["scrolling-content"]} ${
            !hasUserData ? st["space-for-login"] : ""
          }`}
          ref={casinoGamesRef}
        >
          <div className={st["game-list"]}>
            <RaffleSideBar />
            <Accordion
              title={L("casino_games")}
              icon={<CasinoIconNew />}
              id={1}
              classType={["first-of"]}
              defaultClosed={!hasToken}
            >
              <CasinoGames
                miniSide={miniSide}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            </Accordion>

            <Accordion
              title={L("sports")}
              icon={<SportsIcon />}
              id={3}
              defaultClosed
            >
              <SportsList
                mobileToggle={mobileToggle}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            </Accordion>

            <Accordion
              title={L("rainbetGames")}
              customFill={false}
              icon={<NewLogoIcon height="20" width="20" />}
              id={2}
              defaultClosed={!hasToken}
            >
              <RainbetOriginals
                pathname={pathname}
                miniSide={miniSide}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            </Accordion>
          </div>

          <div className={st["sized-bar"]}></div>

          <div className={st["fixed-menu-items"]}>
            <Link
              href="/sportsbook/counter-strike/counter-strike-2/s--perfect-world-shanghaimajor2024-2472883231620468785"
              className={st.sportLink}
            >
              <CsgoIcon
                style={{ minWidth: 15, marginRight: 10 }}
                fill="#ffffff"
              />
              Shanghai Major
              <span>🔥</span>
            </Link>

            {locale === "pt" && (
              <Link
                href="/sportsbook/soccer/brazil/brasileiro-serie-a-1669818812230406144"
                locale="pt"
                className={st.sportLink}
              >
                <Soccer style={{ width: 15, marginRight: 10 }} />
                Serie A Brasil
                <span>🔥</span>
              </Link>
            )}

            <Link
              href="/sportsbook/soccer/international-clubs/uefa-champions-league-1669819100844658688"
              className={st.sportLink}
            >
              <Soccer style={{ minWidth: 15, marginRight: 10 }} />
              UEFA Champions League
              <span>🔥</span>
            </Link>

            <RacesCTA
              type="weekly"
              style={{
                paddingLeft: "12px",
                marginTop: "1rem",
                marginBottom: "10px",
              }}
            />
            <RacesCTA
              type="monthly"
              style={{
                paddingLeft: "12px",
                marginBottom: "10px",
              }}
            />
            <div className={st["bottom-buttons-nav"]}>
              <Button
                buttonType={"link"}
                key={"Key25"}
                url={{
                  pathname: "/rewards",
                }}
                classType={["side-menu-nav"]}
                text={L("rewards")}
                icon={<GiftIcon />}
                activeItem={pathname.includes("/rewards")}
              />

              {/* {menuState === "sports" && <SportsList mobileToggle={mobileToggle} />} */}
              <Button
                buttonType={"link"}
                url={{
                  pathname: "/promotions",
                }}
                classType={["side-menu-nav"]}
                text={L("promotions")}
                icon={<PromotionsIconNav />}
                activeItem={pathname.includes("/promotions")}
              />

              <Button
                buttonType={"link"}
                url={{
                  pathname: "/challenges",
                }}
                classType={["side-menu-nav"]}
                text={L("challenges")}
                icon={<ChallengeSvg width="20px" height="20px" />}
                activeItem={pathname.includes("/challenges")}
              />
              <Button
                buttonType={"link"}
                url={{
                  pathname: resolveDomain("blog") + locale,
                }}
                classType={["side-menu-nav"]}
                text={L("blog")}
                icon={<BlogIcon />}
                activeItem={pathname.includes("/blog")}
              />
            </div>
            <div className={st["promotions-button"]}>
              <div className={st["rewards-container"]}></div>
              <div className={st["rewards-container"]}>
                <Button
                  key={9}
                  method={() => show()}
                  classType={["side-menu-nav"]}
                  text={L("liveSupport")}
                  icon={<LiveSupport />}
                />
              </div>
            </div>
          </div>
        </div>
        {!isBottom && (
          <div className={`${st["gradient"]} ${st["gradient__bottom"]}`}></div>
        )}
        <div
          className={`
                ${st["sidebar-footer"]}
            `}
        >
          <div className={st["lang-back"]}>
            <Language />
          </div>
        </div>
      </div>

      {/* MINI VERSION  --------------------------------------------------- MINI VERSION */}

      <div className={st["mini-version"]}>
        <div className={`${st["game-list"]} `}>
          <RaffleMiniSideBar />
          <FlyoutMenu
            setActiveFlyout={setActiveCasinoFlyout}
            content={
              <CasinoGames miniSide={true} setActiveItem={setActiveItem} />
            }
          >
            <Button
              url={{
                pathname: "/casino/",
              }}
              buttonType={"link"}
              classType={["side-menu-nav", "mini-version"]}
              // text={L("casino")}

              icon={<CasinoIconNew />}
              activeItem={
                menuState === "games" &&
                !pathname.includes("/promotions") &&
                !pathname.includes("/rewards") &&
                !pathname.includes("/originals") &&
                !pathname.includes("/challenges") &&
                activeCasinoFlyout
              }
              // iconRight={"arrow"}
            />
          </FlyoutMenu>

          <FlyoutMenu
            setActiveFlyout={setActiveSportsFlyout}
            content={
              <FlyoutSportsContent
                miniSide={true}
                mobileToggle={mobileToggle}
              />
            }
          >
            <Button
              url={{
                pathname: "/sportsbook",
              }}
              buttonType={"link"}
              classType={["side-menu-nav", "mini-version"]}
              icon={"sports"}
              activeItem={pathname.includes("sports") || activeSportsFlyout}
            />
          </FlyoutMenu>

          <FlyoutMenu
            setActiveFlyout={setActiveOriginalFlyout}
            content={
              <RainbetOriginals
                miniSide={true}
                pathname={pathname}
                setActiveItem={setActiveItem}
              />
            }
          >
            <Button
              url={{
                pathname: "/casino/originals",
              }}
              buttonType={"link"}
              classType={["side-menu-nav", "mini-version"]}
              icon={<NewLogoIcon width="20px" height="20px" />}
              activeItem={
                pathname.includes("/originals") || activeOriginalFlyout
              }
            />
          </FlyoutMenu>
        </div>

        <div className={st["scrolling-content"]}>
          <ToolTip placement="right" size={"small"} text={L("promotions")}>
            <Button
              buttonType={"link"}
              url={{
                pathname: "/promotions",
              }}
              classType={["side-menu-nav", "mini-version"]}
              icon={<PromotionsIconNav />}
              activeItem={pathname.includes("/promotions")}
            />
          </ToolTip>

          <ToolTip placement="right" size={"small"} text={L("challenges")}>
            <Button
              buttonType={"link"}
              url={{
                pathname: "/challenges",
              }}
              classType={["side-menu-nav", "mini-version"]}
              icon={<ChallengeSvg width="20px" height="20px" />}
              activeItem={pathname.includes("/challenges")}
            />
          </ToolTip>

          <ToolTip placement="right" size={"small"} text={L("blog")}>
            <Button
              buttonType={"link"}
              url={{
                pathname: resolveDomain("blog") + locale,
              }}
              classType={["side-menu-nav", "mini-version"]}
              icon={<BlogIcon />}
              activeItem={pathname.includes("/blog")}
            />
          </ToolTip>

          <ToolTip placement="right" size={"small"} text={L("rewards")}>
            <Button
              key={"test3234"}
              buttonType={"link"}
              url={{
                pathname: "/rewards",
              }}
              classType={["side-menu-nav", "mini-version"]}
              icon={<GiftIcon />}
              activeItem={pathname.includes("/rewards")}
            />
          </ToolTip>

          <ToolTip placement="right" size={"small"} text={L("liveSupport")}>
            <Button
              method={() => show()}
              classType={["side-menu-nav", "mini-version"]}
              icon={<LiveSupport />}
            />
          </ToolTip>
        </div>

        <div className={st["sidebar-footer"]}>
          <div className={st["lang-back"]}>
            <Language mini={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export const isLiveCasinoActive = (pathname) => {
  if (pathname.endsWith("/casino/live")) return true;
  const excludePaths = [
    "/casino/live/blackjack",
    "/casino/live/baccarat",
    "/casino/live/roulette",
    "/casino/live/game-shows",
  ];

  return (
    pathname.includes("/casino/live/") &&
    !excludePaths.some((excludePath) => pathname.includes(excludePath))
  );
};
