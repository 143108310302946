import PubSub, { EVENTS } from "../pubsub";

const dicts = {};

const client = (locale) => {
  dicts[locale] =
    dicts[locale] ||
    fetch(`/languages/${locale}.json`)
      .catch(() => fetch("/languages/en.json"))
      .then((res) => res.json())
      .then((res) => {
        PubSub.publishSync(EVENTS.DICT, res);
        return res;
      });

  return dicts[locale];
};

const server = (locale) => {
  dicts[locale] =
    dicts[locale] ||
    import(`../../public/languages/${locale}.json`)
      .catch(() => import("../../public/languages/en.json"))
      .then((res) => {
        PubSub.publishSync(EVENTS.DICT, res.default);
        return res.default;
      });

  return dicts[locale];
};

export default typeof window === "undefined" ? server : client;
