const SportsFav = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      data-icon-name="star"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M245 8c3-10 19-10 22 0l66 178 167 2c11 0 16 15 7 22L372 313l48 184c3 11-10 19-19 13L256 403 111 510c-9 6-22-2-19-13l48-184L5 210c-9-7-4-22 7-22l167-2z"
      />
    </svg>
  )
  export default SportsFav