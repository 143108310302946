import { useState, useEffect, useRef } from "react";
import st from "../../styles/components/SideBar.module.scss";
import Button from "../../items/Button";
import SportsIcon from "../../assets/icons/general/Sports";
import BaseBall from "../../assets/icons/sports/Baseball";
import CSGO from "../../assets/icons/sports/CSGO";
import LOL from "../../assets/icons/sports/LOL";
import Dota from "../../assets/icons/sports/Dota";
import Valorant from "../../assets/icons/sports/Valorant";
import Boxing from "../../assets/icons/sports/Boxing";
import Tennis from "../../assets/icons/sports/Tennis";
import IceHockey from "../../assets/icons/sports/IceHockey";
import MMA from "../../assets/icons/sports/MMA";
import AmericanFootball from "../../assets/icons/sports/AmericanFootball";
import Basketball from "../../assets/icons/sports/Basketball";
import Soccer from "../../assets/icons/sports/Soccer";
import SportsFav from "../../assets/icons/sports/SportsFav";
import SportsResults from "../../assets/icons/sports/SportsResults";
import SportsSchedule from "../../assets/icons/sports/SportsSchedule";
import ESports from "../../assets/icons/sports/ESports";
import useLanguage from "../../hooks/useLanguage";
import ToolTip from "../../items/ToolTip";
import { useRouter } from "next/router";
import { useUserSession } from "../../hooks/useUserSession";

export default function SportsList({
  mobileToggle,
  mini,
  activeItem,
  setActiveItem,
}) {
  const L = useLanguage("SportsList");
  const [currentUrl, setCurrentUrl] = useState(null);
  const router = useRouter();
  const { hasUserData } = useUserSession();

  //Changes url inside iframe
  const changeIframe = (game) => {
    router.push({
      pathname: `/sportsbook/${game}`,
      shallow: true,
    });
  };

  // Handle item click
  const handleItemClick = (value) => {
    setActiveItem?.(value);
    setCurrentUrl(value);
    changeIframe(value);
  };

  // Array of objects representing the sports
  const topList = [
    hasUserData && {
      name: L("Favorites"),
      icon: <SportsFav />,
      value: "favorites",
    },
    // { name: L("Results"), icon: <SportsResults />, value: "results" },
    { name: L("Schedule"), icon: <SportsSchedule />, value: "schedule" },
  ].filter(Boolean);

  const eSportList = [
    { name: L("CS:GO"), icon: <CSGO />, value: "counter-strike-109" },
    {
      name: L("League of Legends"),
      icon: <LOL />,
      value: "league-of-legends-110",
    },
    { name: L("Dota 2"), icon: <Dota />, value: "dota-2-111" }, // Update this if you have the correct value
    { name: L("Valorant"), icon: <Valorant />, value: "esport-valorant-194" },
    // { name: L("Starcraft 2"), icon: <StarCraft />, value: "starcraft2" }, // Update this if you have the correct value
    // { name: L("Overwatch"), icon: <Overwatch />, value: "overwatch" }, // Uncomment and update this if you have the correct value
  ];

  const sportsList = [
    {
      name: L("American Football"),
      icon: <AmericanFootball />,
      value: "american-football-16",
    },
    { name: L("Soccer"), icon: <Soccer />, value: "soccer-1" },
    { name: L("Baseball"), icon: <BaseBall />, value: "baseball-3" },
    { name: L("Boxing"), icon: <Boxing />, value: "boxing-10" }, // Update this if you have the correct value
    { name: L("Tennis"), icon: <Tennis />, value: "tennis-5" },
    { name: L("Ice Hockey"), icon: <IceHockey />, value: "ice-hockey-4" },
    { name: L("MMA"), icon: <MMA />, value: "mma-117" }, // Update this if you have the correct value
    { name: L("Basketball"), icon: <Basketball />, value: "basketball-2" },
  ];

  return (
    <div className={st["game-list"]}>
      {topList.map((sport, index) =>
        mini ? (
          <ToolTip
            placement="right"
            size={"big"}
            text={sport.name}
            key={sport.value}
          >
            <div>
              <Button
                method={() => handleItemClick(sport.value)}
                classType={["side-menu-nav", "mini-version"]}
                icon={sport.icon}
                activeItem={sport.value === activeItem}
              />
            </div>
          </ToolTip>
        ) : (
          <Button
            key={sport.name}
            method={() => handleItemClick(sport.value)}
            classType={["side-menu-nav"]}
            text={sport.name}
            icon={sport.icon}
            activeItem={sport.value === activeItem}
          />
        ),
      )}

      {!mini && (
        <>
          {eSportList.map((sport) => (
            <Button
              key={sport.name}
              method={() => handleItemClick(sport.value)}
              classType={["side-menu-nav"]}
              text={sport.name}
              icon={sport.icon}
              activeItem={sport.value === activeItem}
            />
          ))}

          {sportsList.map((sport) => (
            <Button
              key={sport.name}
              method={() => handleItemClick(sport.value)}
              classType={["side-menu-nav"]}
              text={sport.name}
              icon={sport.icon}
              activeItem={sport.value === activeItem}
            />
          ))}
          {/* </Accordion> */}
        </>
      )}

      {mini && (
        <>
          {topList.map((sport) =>
            mini ? (
              <ToolTip
                placement="right"
                size={"big"}
                text={sport.name}
                key={sport.value}
              >
                <div>
                  <Button
                    method={() => handleItemClick(sport.value)}
                    classType={["side-menu-nav", "mini-version"]}
                    icon={sport.icon}
                    activeItem={sport.value === activeItem}
                  />
                </div>
              </ToolTip>
            ) : (
              <Button
                key={sport.name}
                method={() => handleItemClick(sport.value)}
                classType={["side-menu-nav"]}
                text={sport.name}
                icon={sport.icon}
                activeItem={sport.value === activeItem}
              />
            ),
          )}
          <div className={st["game-list-heading"]}>
            <div className={st["icon"]}>
              <ESports style={{ marginLeft: "3px" }} />
            </div>
          </div>
          {eSportList.map((sport) => (
            <ToolTip
              placement={"right"}
              size={"big"}
              text={sport.name}
              key={sport.value}
            >
              <div>
                <Button
                  method={() => handleItemClick(sport.value)}
                  classType={["side-menu-nav", "mini-version"]}
                  icon={sport.icon}
                  activeItem={sport.value === activeItem}
                />
              </div>
            </ToolTip>
          ))}
          <div className={st["game-list-heading"]}>
            <div className={st["icon"]}>
              <SportsIcon style={{ marginLeft: "3px" }} />
            </div>
          </div>
          {sportsList.map((sport) => (
            <ToolTip
              placement={"right"}
              size={"big"}
              text={sport.name}
              key={sport.value}
            >
              <div>
                <Button
                  method={() => handleItemClick(sport.value)}
                  classType={["side-menu-nav", "mini-version"]}
                  icon={sport.icon}
                  activeItem={sport.value === activeItem}
                />
              </div>
            </ToolTip>
          ))}
        </>
      )}
    </div>
  );
}
