const ESports = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={20}
    height={20}
    data-icon-name="cybersport"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="currentColor"
      d="M149 205v-21c0-26-42-26-42 0v21H85c-28 0-26 41 0 41h22v20c0 25 42 27 42 0v-20h22c27 0 27-41 0-41zm-21-103h256c86 0 155 90 117 173-15 19-27 32-3 62 23 64-85 104-109 35-10-29-19-21-48-31-20-7-38-19-52-34h-66c-14 15-32 27-52 34-27 9-34 4-44 31-25 65-129 29-109-35 20-30 8-43-7-62-38-81 29-173 117-173zm256 103c11 0 21-9 21-21 0-11-10-20-21-20s-21 9-21 20c0 12 10 21 21 21zm-43 41c12 0 22-9 22-21 0-11-10-20-22-20-11 0-21 9-21 20 0 12 10 21 21 21zm86 0c11 0 21-9 21-21 0-11-10-20-21-20-12 0-22 9-22 20 0 12 10 21 22 21zm-43 40c11 0 21-9 21-20 0-12-10-20-21-20s-21 8-21 20c0 11 10 20 21 20z"
    />
  </svg>
);
export default ESports;
