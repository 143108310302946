export default function BlogIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      {...props}
    >
      <path
        d="M11.8986 2.23698C12.2001 2.23698 12.4444 2.48134 12.4444 2.78278V13.3578C12.4444 13.6593 12.2001 13.9036 11.8986 13.9036H2.10136C1.79992 13.9036 1.55556 13.6593 1.55556 13.3578V2.78278C1.55556 2.48134 1.79992 2.23698 2.10136 2.23698H11.8986ZM12.4444 0.570312H1.55556C0.7 0.570312 0 1.32031 0 2.23698V13.9036C0 14.8203 0.7 15.5703 1.55556 15.5703H12.4444C13.3 15.5703 14 14.8203 14 13.9036V2.23698C14 1.32031 13.3 0.570312 12.4444 0.570312Z"
        fill="#E8E5FF"
        fillOpacity="1"
      />
      <path
        d="M9.53214 11.3113C9.53214 11.7532 9.17396 12.1113 8.73214 12.1113H3.8C3.35817 12.1113 3 11.7532 3 11.3113C3 10.8695 3.35817 10.5113 3.8 10.5113H8.73214C9.17396 10.5113 9.53214 10.8695 9.53214 11.3113ZM11 8.11133C11 8.55316 10.6418 8.91133 10.2 8.91133H3.8C3.35817 8.91133 3 8.55316 3 8.11133C3 7.6695 3.35817 7.31133 3.8 7.31133H10.2C10.6418 7.31133 11 7.6695 11 8.11133ZM9.87998 4.91133C9.87998 5.35316 9.52181 5.71133 9.07998 5.71133H3.8C3.35817 5.71133 3 5.35316 3 4.91133C3 4.4695 3.35817 4.11133 3.8 4.11133H9.07998C9.52181 4.11133 9.87998 4.4695 9.87998 4.91133Z"
        fill="#E8E5FF"
        fillOpacity="1"
      />
    </svg>
  );
}
